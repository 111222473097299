<!--

      This view displays the list of not yet ordered menus. 
      It is reachable from within the shopping tab.

 -->

<template>
  <Page title="Nicht gekaufte Menüs">
    <MenuCard
      v-for="openPlan in openPlansSorted"
      :key="openPlan.id" 
      :openPlan="openPlan"
    />
    <ion-infinite-scroll
      @ionInfinite="this.loadNextPage($event)" 
      threshold="500px"
      v-if="this.$store.state.openOrdersLastPage && this.$store.state.openOrdersLastPage.next"
    >
      <ion-infinite-scroll-content
        class="infinite-scrolling-loading"
        loading-spinner="bubbles">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </Page>
</template>

<script>
import Page from '@/components/Page.vue'
import MenuCard from '@/components/cards/MenuCard.vue'
import {
  IonInfiniteScroll,
  IonInfiniteScrollContent
} from '@ionic/vue'

export default {
  name: 'menus',
  components: {
    Page,
    MenuCard,
    IonInfiniteScroll,
    IonInfiniteScrollContent
  },
  computed: {
    openPlansSorted () {
      if (this.$store.state.openPlans) {
        const plans = JSON.parse(JSON.stringify(this.$store.state.openPlans))
        return plans.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
      }
    }
  },
  methods: {
    loadNextPage(event) {
      if (this.$store.state.openOrdersLastPage) {
        const url = this.$store.state.openOrdersLastPage.next
        this.$store.dispatch('getOpenOrdersAndPlans', url).then(() => {
          if (this.$store.state.openOrdersLastPage.hasOwnProperty('results')) {
            if (event) {
              event.target.complete()
              if (!this.$store.state.openOrdersLastPage.next) {
                event.target.disabled = true
              }
            }
          }
        })
      }
    }
  },
  updated () {
    // This function only loads the first page:
    this.$store.dispatch('getOpenOrdersAndPlans')
  }
}
</script>
